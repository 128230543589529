<template>
  <v-row align="center" style="height: 300px;">
    <v-spacer/>
    <v-col cols="auto">
      <v-alert outlined type="warning">
        <span v-html="text"></span>
      </v-alert>
    </v-col>
    <v-spacer/>
  </v-row>
</template>

<script>
export default {
  data: function () {
    return {
      text: this.$t('m.qrCodeInactive.text')
    }
  }
}
</script>
